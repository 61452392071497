//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';

export default {
  mixins: [
    FormMixins,
    CalculateConditionalLogicMixins
  ],
  methods: {
    detailsUpdated(errors) {
      this.$emit('detailsUpdated', {
        fieldName: this.data.fieldName,
        value: this.input,
        errors: errors
      });
    },
  }
};
