//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';

export default {
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  computed: {
    charLimit() {
      return this.data.maxFileSize + 'MB upload size maximum';
    },
  },
  methods: {
    processFile(event) {
      this.input = event.target.files[0];
    },
  },
};
