//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from '~/util/helper';
import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';
import abnLookup from './AbnLookup';

export default {
  components: {
    abnLookup,
  },
  mixins: [ FormMixins,
    CalculateConditionalLogicMixins ],
  data() {
    return {
      errors: [],
      input: '',
      businessAddress: '',
    };
  },
  props: {
    sub: {
      default: false,
      type: Boolean,
    },
  },
  created() {
    this.$nuxt.$on('orgAddressAdded', (businessAddress) => {
      this.input = businessAddress;
    });
  },
  computed: {
    delayed() {
      return debounce(this.delayUpdated, 250);
    },
  },
 
  methods: {
    onChange(e) {
      this.input = e.target.value;
    },
  },

};
