var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('div',{staticClass:"gform-wrapper"},[_c('transition-group',{attrs:{"name":"fade"}},[_c('div',{key:'form_id_' + _vm.form.id + '_form',staticClass:"gform-wrapper"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [(_vm.form)?_c('form',{ref:'form_id__' + _vm.form.id,on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"ui-form-field__columns"},_vm._l((_vm.reformatFields),function(col,index){return _c('div',{key:index,staticClass:"ui-form-field__column",class:'form_field_id__' +
                  _vm.form.id +
                  (Array.isArray(col) ? ' columns' : '')},[(Array.isArray(col))?_c('div',{staticClass:"ui-form-field__column__inner"},_vm._l((col),function(field,innerIndex){return _c(_vm.getComponentName(field),{key:innerIndex,tag:"component",attrs:{"data":field,"id":_vm.form.id,"clear":_vm.clearFields,"formErrors":errors}})}),1):_c('div',[_c(_vm.getComponentName(col),{tag:"component",attrs:{"data":col,"id":_vm.form.id,"clear":_vm.clearFields,"formErrors":errors}})],1)])}),0),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formError),expression:"formError"}],key:"form_error",staticClass:"error-wrapper"},[_c('div',{staticClass:"gform-error"},[_vm._v(_vm._s(_vm.formError))])]),_vm._v(" "),(_vm.form.button)?_c('button',{staticClass:"chr-button gform_button button",class:{
              spin: _vm.isSubmitting,
            },attrs:{"type":"submit"}},[_c('span',{staticClass:"text",domProps:{"textContent":_vm._s(_vm.form.button.text)}}),_vm._v(" "),_c('span',{staticClass:"loading"},[_c('loading')],1)]):_vm._e()]):_vm._e()]}}],null,false,3622826533)})],1),_vm._v(" "),(_vm.confirmationMessage)?_c('div',{key:'form_id_' + _vm.form.id + '_confirmations',staticClass:"confirmtaions-wrapper margin-top-2"},[_c('div',{staticClass:"gform-confirmations"},[_c('div',{staticClass:"confirmations"},[_c('div',{staticClass:"confirmation"},[_c('div',{staticClass:"text wysiwyg-editor",domProps:{"innerHTML":_vm._s(_vm.confirmationMessage)}})])])])]):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }