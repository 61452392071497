//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixins from '~/mixins/form';
import xmlToJSON from 'xmltojson';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';
import Spinner from '~/static/svg/spinner.svg';

export default {
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  data() {
    return {
      errors: [],
      input: '',
      abnError: false,
      orgName: '',
      lookupError: '',
      isLoading: false,
    };
  },
  components: {
    Spinner,
  },
  props: {
    sub: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    abnValidationRules() {
      let rules = {
        abnValidation: [
          {
            isValid: this.isABNValid,
            errorMsg: this.$t('form.validations.acn_lookup'),
          },
        ],
      };
      return rules;
    },
    isABNValid() {
      if (this.abnError === false) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    'data.defaultValue'(value) {
      this.input = value;
    },
  },
  methods: {
    async handleBlur() {
      this.isLoading = true;
      const abnNum = this.input;
      try {
        const xml = await this.$axios.get(
          `https://abr.business.gov.au/abrxmlsearch/AbrXmlSearch.asmx/SearchByASICv201408?searchString=${abnNum}&includeHistoricalDetails=Y&authenticationGuid=aef1da4e-417b-49f8-9657-09e5f3fa3a0f`
        );

        const xmlString = xml.data;
        const result = await xmlToJSON.parseString(xmlString);
        if (result.ABRPayloadSearchResults[0]?.response[0]?.exception) {
          this.abnError = true;
        } else {
          const data =
            result.ABRPayloadSearchResults[0].response[0]
              .businessEntity201408[0];
          if (data.mainName) {
            this.orgName = data?.mainName[0]?.organisationName[0]._text || '';
          } else {
            this.orgName =
              data?.businessName[0]?.organisationName[0]._text || '';
          }

          const postal =
            data?.mainBusinessPhysicalAddress[0]?.postcode[0]._text || '';
          const state =
            data?.mainBusinessPhysicalAddress[0]?.stateCode[0]._text || '';
          const businessAddress = postal + ', ' + state;
          this.abnError = false;
          this.$nuxt.$emit('orgNameAdded', this.orgName);
          this.$nuxt.$emit('orgAddressAdded', businessAddress);
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.lookupError = 'Unable to validate, please try again';
      }
    },
  },
};
