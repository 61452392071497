//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';

export default {
  mixins: [
    FormMixins,
    CalculateConditionalLogicMixins
  ],
};
