const reCaptchaTokenMixin = {
  methods: {
    async getRecaptchaToken( $type = null ) {
      if ( $type ) {
        return await this.$recaptcha.execute( $type );
      }
      return await this.$recaptcha.execute();
    },
  }
};

export default reCaptchaTokenMixin;
