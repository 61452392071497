//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';
import imageCompression from 'browser-image-compression';

export default {
  data() {
    return {
      errors: [],
      input: null,
    };
  },
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  computed: {
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
  },

  methods: {
    async processFile(event) {
      const TWO_MB_MAX = 2000000;
      if (event.target.files[0].size > TWO_MB_MAX) {
        const file = await imageCompression(event.target.files[0], {
          maxSizeMB: 2,
        });
        this.input = file;
      } else {
        return (this.input = event.target.files[0]);
      }
    },
  },
};
