//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';

export default {
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  data() {
    return {
      // Override default mixin data object, this component does not need an input
    };
  },
  computed: {
    transformedFields() {
      if (!this.data) return [];
      const inputs = this.data.inputs
        .filter(input => !input.isHidden)
        .map(input => ({
          ...this.data,
          inputs: null,
          label: input.label,
          placeholder: input.placeholder,
          id: input.id,
          name: input.name,
          defaultValue: input.defaultValue,
        }));
      return inputs;
    },
  },
};
