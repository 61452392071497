var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShow),expression:"shouldShow"}],staticClass:"ui-form-field",class:_vm.customClass},[_c('div',{staticClass:"ui-form-field__label-container"},[_c('div',{staticClass:"ui-form-field__label"},[(_vm.data.label)?_c('div',{staticClass:"text",domProps:{"textContent":_vm._s(_vm.data.label)}}):_vm._e(),_vm._v(" "),(_vm.data.isRequired)?_c('div',{staticClass:"required"},[_vm._v("*")]):_vm._e()]),_vm._v(" "),(_vm.data.tooltip && _vm.data.tooltip.innerHTML)?_c('bd-tooltip',{attrs:{"innerHTML":_vm.data.tooltip.innerHTML,"innerHeading":_vm.data.tooltip.innerHeading,"label":_vm.data.tooltip.label,"tooltipWidth":_vm.data.tooltip.tooltipWidth,"position":_vm.data.tooltip.position,"iconSize":_vm.data.tooltip.iconSize,"tooltipBackground":_vm.data.tooltip.tooltipBackground,"iconPosition":_vm.data.tooltip.iconPosition}}):_vm._e()],1),_vm._v(" "),_c('validation-provider',{attrs:{"vid":'validate_'+_vm.fieldId,"rules":_vm.rules,"customMessages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"ui-form-field__input-wrapper",class:errors.length > 0 ? 'has-error' : ' ' + _vm.data.size},[_c('div',{staticClass:"inner-wrapper"},[(_vm.data.placeholder && _vm.data.placeholder.length > 0)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isClean),expression:"isClean"}],staticClass:"ui-form-field__placeholder",domProps:{"textContent":_vm._s(_vm.data.placeholder)}}):_vm._e(),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"ui-form-field__textarea",class:{
            'has-error': errors.length > 0
          },attrs:{"name":_vm.data.label},domProps:{"value":(_vm.input)},on:{"change":function($event){return _vm.detailsUpdated(errors)},"input":function($event){if($event.target.composing){ return; }_vm.input=$event.target.value}}})]),_vm._v(" "),(_vm.charLimit)?_c('div',{staticClass:"ui-form-field__char-limit",domProps:{"textContent":_vm._s(_vm.charLimit)}}):_vm._e(),_vm._v(" "),(_vm.data.description)?_c('div',{staticClass:"ui-form-field__instruction",class:{
        top: _vm.data.descriptionPlacement === 'top',
        bottom: _vm.data.descriptionPlacement === 'bottom',
      }},[_c('span',{domProps:{"textContent":_vm._s(_vm.data.description)}})]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"ui-form-field__error"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }