//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StorageMixins from '~/mixins/storage';
import reCaptchaTokenMixin from '~/mixins/recaptchaToken';
import { mapGetters, mapActions } from 'vuex';
import Loading from '~/static/svg/loading.svg';
import UiConsent from './form/UiConsent';
import UiDate from './form/UiDate';
import UiFileupload from './form/UiFileupload';
import UiHidden from './form/UiHidden';
import UiHtml from './form/UiHtml';
import UiName from './form/UiName';
import UiRadio from './form/UiRadio';
import UiSection from './form/UiSection';
import UiSelect from './form/UiSelect';
import UiText from './form/UiText';
import UiTextarea from './form/UiTextarea';
import UiTime from './form/UiTime';
import UiCheckbox from './form/UiCheckbox';
import AbnLookup from './form/AbnLookup';
import AcnLookup from './form/AcnLookup';
import BusinessName from './form/BusinessName';
import BusinessAddress from './form/BusinessAddress';
import UiImageupload from './form/UiImageupload';

import { api } from '~/store/users';

const CLEAR_MESSAGE_TIMEOUT = 30000;

export default {
  components: {
    Loading,
    UiConsent,
    UiDate,
    UiFileupload,
    UiHidden,
    UiHtml,
    UiName,
    UiRadio,
    UiSection,
    UiSelect,
    UiText,
    UiTextarea,
    UiTime,
    UiCheckbox,
    AbnLookup,
    AcnLookup,
    BusinessName,
    BusinessAddress,
    UiImageupload,
  },
  mixins: [reCaptchaTokenMixin, StorageMixins],
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      confirmationMessage: false,
      clearFields: false,
      isSubmitting: false,
      formError: false,
      formDataValues: [],
    };
  },
  watch: {
    confirmationMessage(val) {
      if (val) {
        setTimeout(() => {
          this.confirmationMessage = false;
        }, CLEAR_MESSAGE_TIMEOUT);
      }
    },
  },
  computed: {
    ...mapGetters({
      getFormResponseFromStore: 'gforms/getFormResponse',
      getForm: 'gforms/getForm',
    }),
    form() {
      return this.getForm(this.id);
    },
    reformatFields() {
      let columns = [];
      let validColumns = [];
      let row = [];
      const skipTypes = [
        'column_start',
        'column_break',
        'column_end',
        'captcha',
      ];
      let insert = false;

      if (!this.form || !this.form.fields) {
        return [];
      }

      this.form.fields.forEach(field => {
        if (field.type === 'column_start') {
          insert = true;
        } else if (field.type === 'column_end') {
          insert = false;
        } else if (field.type === 'column_break') {
          columns.push(row);
          row = [];
        }

        if (insert) {
          if (!skipTypes.includes(field.type)) {
            row.push(field);
          }
        } else {
          if (row.length > 0) {
            columns.push(row);
          } else if (!skipTypes.includes(field.type)) {
            columns.push(field);
          }
          row = [];
        }
      });

      for (let i = 0; i < columns.length; i++) {
        if (columns[i].type !== 'hidden') {
          validColumns.push(columns[i]);
        }
      }
      columns = validColumns;
      return columns;
    },
  },
  methods: {
    ...mapActions({
      getSingleForm: 'gforms/getForm',
      submitForm: 'gforms/submitForm',
      clearForm: 'gforms/clearForm',
      getUserByEmail: 'users/getUserByEmail',
      createNewUser: 'users/createNewUser',
      createNewUserContact: 'users/createNewUserContact',
      createNewEcommUser: 'users/createNewEcommUser',
    }),
    getComponentName(field) {
      if (
        field.type === 'email' ||
        field.type === 'phone' ||
        field.type === 'number'
      ) {
        return 'UiText';
      } else if (field.type === 'multiselect') {
        return 'UiSelect';
      } else if (field.label === 'Australian Company Number') {
        return 'AcnLookup';
      } else if (field.label === 'Australian Business Number') {
        return 'AbnLookup';
      } else if (field.label === 'Business Name') {
        return 'BusinessName';
      } else if (field.label === 'Business Address') {
        return 'BusinessAddress';
      } else if (
        field.label ===
        'Copy of Drivers licence required for apprentice preferred option'
      ) {
        return 'UiImageupload';
      } else {
        return 'Ui' + field.type.charAt(0).toUpperCase() + field.type.slice(1);
      }
    },
    async getUserIp() {
      try {
        const ip = await this.$axios.get('https://api.ipify.org/?format=json');
        if (ip.status === 200) {
          return ip.data.ip;
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    async onSubmit() {
      if (this.isSubmitting) return;
      const observer = this.$refs.observer;
      const result = await observer.validate();
      if (!result) {
        for (const key of Object.keys(observer.fields).sort()) {
          if (observer.fields[key] && observer.fields[key].invalid) {
            if (observer.refs && observer.refs[key] && observer.refs[key].$el) {
              observer.refs[key].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              });
            }
            return;
          }
        }

        return;
      }

      this.formError = false;
      this.isSubmitting = true;
      let data = new FormData();
      const recaptcha_token = await this.getRecaptchaToken();
      data.append('recaptcha_token', recaptcha_token);
      let formResponse = this.getFormResponseFromStore(this.id);
      if (formResponse) {
        Object.keys(formResponse).forEach(index => {
          const response = formResponse[index];
          const fieldKey = 'input_' + response.key.toString().replace('.', '_');
          let fieldData = this.getSingleResponse(response);
          let inputString = String(fieldData).toLowerCase();
          this.formDataValues.push({ index, inputString });

          if (fieldData instanceof Blob) {
            if (inputString.includes('mount')) {
              const replaceText = inputString.replace('mount', 'Mt.');
              fieldData = replaceText;
            }

            data.append(fieldKey, fieldData, fieldData.name);
          } else {
            if (inputString.includes('mount')) {
              const replaceText = inputString.replace('mount', 'Mt.');
              fieldData = replaceText;
            }
            data.append(fieldKey, fieldData);
          }
        });
      }

      try {
        let response = await this.submitForm({
          id: this.id,
          data: data,
        });
        await this.handleConfirmations(response);
      } catch (err) {
        this.isSubmitting = false;
        this.formError = err.message;
        this.captureSentryError(err);
      }
    },
    getSingleResponse(response) {
      if (response.type === 'multiselect') {
        let value = [];
        response.value.forEach(val => {
          value.push(val.code);
        });
        return value;
      } else if (response.type === 'select') {
        return response.value && response.value.code
          ? response.value.code
          : undefined;
      } else {
        return response.value;
      }
    },
    getValueOfForm(id) {
      let value = undefined;
      Array.prototype.forEach.call(this.formDataValues, values => {
        if (parseInt(values.index) === id) {
          value = values.inputString;
        }
      });
      return value;
    },
    populateParamsByField(fields) {
      let params = {
        name: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        suburb: '',
        state: '',
        postCode: '',
        dateTime: '',
        storeId: 11, // Default to 11 if no stores
      };
      let firstName = '';
      let lastName = '';
      let companyName = '';

      const envUrl = this.$config.baseUrl;
      let suburbId = 36;
      if (envUrl.includes('local') || envUrl.includes('uat')) suburbId = 29;

      let companyId = 38;
      if (envUrl.includes('local')) companyId = 35;
      else if (envUrl.includes('uat')) companyId = 45;

      for (let i = 0; i < fields.length; i++) {
        if (fields[i].id === 27) {
          // Email field
          params.email = this.getValueOfForm(fields[i].id);
        } else if (fields[i].id === 1) {
          // First name field
          firstName = this.getValueOfForm(fields[i].id);
        } else if (fields[i].id === 31) {
          // Last name field
          lastName = this.getValueOfForm(fields[i].id);
        } else if (fields[i].id === companyId) {
          // Company name field: Local id: 35, Uat id: 45, prod id: 38
          companyName = this.getValueOfForm(fields[i].id);
        } else if (fields[i].id === suburbId) {
          // Suburb field: Local and UAT id: 29, prod id: 36
          params.suburb = this.getValueOfForm(fields[i].id);
          params.address = this.getValueOfForm(fields[i].id);
        } else if (fields[i].id === 26) {
          // Phone field
          let phone = this.getValueOfForm(fields[i].id);
          phone = phone.replace(/ /g, '');
          params.phone = phone;
        } else if (fields[i].id === 28) {
          // Post code field
          params.postCode = this.getValueOfForm(fields[i].id);
        } else if (fields[i].id === 30) {
          // Store field
          params.storeId = this.getValueOfForm(fields[i].id);
        }
      }

      params.name = firstName + ' ' + lastName;
      params.company = companyName ? companyName : params.name;
      params.state = 'VIC';

      const now = new Date().toISOString();
      params.dateTime = now.substring(0, 10);

      return params;
    },
    async handleCustomerCreationOrReset(params) { 
      let success = false;
      if (params.email) {
        const email = params.email;
        const user = await this.getUserByEmail({ email });
        if (user && user.email) {
          const response = await api.requestReset(this.$axios, user.email);
          if (!response || response.error) {
            success = false;
            this.formError = this.$t(
              'my_account.login.recover_password.error_msg.others'
            );
          }
          this.confirmationMessage =
            'User has an existing account, reset password email has been sent.';
          success = true;
        } else {
          // Create new guest user here.
          const newGuestUser = await this.createNewUser(params);
          if (newGuestUser && !newGuestUser.hasErrors) {
            const contactParams = {
              idCust: newGuestUser.idCust,
              contactType: 'web',
              contactName: params.name,
              emailAddress: params.email,
              phoneMobile: params.phone,
              phoneHome: undefined,
              phoneBusiness: undefined,
              faxNumber: undefined,
              barcode: undefined,
              attribute: [
                {
                  attributeCategory: 'CRM',
                  attributeName: 'ECOMM ACCESS',
                  attributeValue: 'yes',
                },
                {
                  attributeCategory: 'MARKETING',
                  attributeName: 'MARKETING COMMUNICATIONS EMAIL',
                  attributeValue: 'yes',
                },
                {
                  attributeCategory: 'MARKETING',
                  attributeName: 'MARKETING COMMUNICATIONS SMS',
                  attributeValue: 'yes',
                },
              ],
            };

            const newGuestUserContact = await this.createNewUserContact(
              contactParams
            );
            if (newGuestUserContact && !newGuestUserContact.hasErrors) {
              // Proceed to create new ecomm user here.
              const ecommParams = {
                email: params.email,
                contactName: params.name,
                mobileNo: params.phone,
                contactType: 'ECOMM',
                active: true,
                activationSent: false,
                customerId: newGuestUser.idCust,
                branchId: params.storeId,
                requiresPurchaseOrder: false,
              };
              const response = await this.createNewEcommUser(ecommParams);

              if (!response || response.error) {
                success = false;
                this.formError = 'Unable to create ecomm user.';
              }

              success = true;
            } else {
              this.isSubmitting = false;
              this.formError = 'Unable to create guest user contact';
              success = false;
            }
          } else {
            this.isSubmitting = false;
            this.formError = 'Unable to create guest user';
            success = false;
          }
        }
      }
      return success;
    },
    async handleConfirmations(response) {
      let success = true;
      if (response.is_valid) {
        this.$gtm.push({
          event: 'gravityFormSubmission',
          gfformID: this.form.id,
        });

        // Only do this for loyalty form. ID is 13.
        if (this.form.id === 13) {
          const param = this.populateParamsByField(this.form.fields);
          success = await this.handleCustomerCreationOrReset(param);
        }
        if (!success) {
          return;
        }

        this.formDataValues = [];
        this.$refs['form_id__' + this.form.id].reset();
        this.clearForm({
          formID: this.form.id,
        });
        this.clearFields = !this.clearFields;
        if (response.confirmation_type === 'redirect') {
          if (response.confirmation_redirect.includes(this.$config.baseUrl)) {
            this.$router.push(
              response.confirmation_redirect.split(this.$config.baseUrl)[1]
            );
          } else {
            location.href = response.confirmation_redirect;
          }
        } else {
          this.confirmationMessage = response.confirmation_message;
        }
      } else {
        this.$sentry.setContext('gravity form', {
          formId: this.form.id,
          formName: this.form.title,
          errorType: 'validation',
        });
        this.$sentry.setTag('path', this.$route.path);
        this.$sentry.setTag('form', this.form.title);
        this.$sentry.captureMessage(
          `GForm: ${this.form.title} - Validation error returned from API`,
          {
            level: 'error',
            extra: {
              formID: this.form.id,
              formName: this.form.title,
              validation_messages: response.validation_messages,
            },
          }
        );
        this.formError = response.validation_messages
          ? this.generateErrorMessage(response.validation_messages)
          : response.message;
      }

      this.isSubmitting = false;
    },
    generateErrorMessage(messages) {
      if (typeof messages === 'object') {
        return Object.values(messages).join('\n\n');
      }
      return messages.toString();
    },
    captureSentryError(error) {
      this.$sentry.setContext('gravity form', {
        formId: this.form.id,
        formName: this.form.title,
        errorType: 'submit error',
      });
      this.$sentry.setTag('path', this.$route.path);
      this.$sentry.setTag('form', this.form.title);
      this.$sentry.captureException(error, {
        level: 'error',
        extra: {
          formID: this.form.id,
          formName: this.form.title,
        },
      });
    },
  },
  serverPrefetch() {
    return this.getSingleForm(this.id);
  },
  async mounted() {
    if (!this.form) {
      await this.getSingleForm(this.id);
    }
  },
};
