//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';

export default {
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  computed: {
    rules() {
      let allRules = {};
      if (this.data.isRequired) {
        allRules = {
          ...{
            required: {
              allowFalse: false,
            },
          },
        };
      }
      if (this.data.customRule) {
        allRules = {
          ...allRules,
          ...this.data.customRule,
        };
      }

      return allRules;
    },
  },
  methods: {
    detailsUpdated(errors) {
      setTimeout(() => {
        this.$emit('detailsUpdated', {
          fieldName: this.data.fieldName,
          value: this.input,
          errors: errors,
        });
      }, 0);
    },
  },
};
