//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';
import 'vue2-timepicker/dist/VueTimepicker.css';

export default {
  components: {
    Timepicker: () => {
      if (process.client) {
        return import('vue2-timepicker/src');
      }
    },
  },
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  computed: {
    rules() {
      let rules = 'timepicker';
      if (this.data.isRequired) {
        rules += '|required';
      }
      return rules;
    },
    format() {
      if (this.data.timeFormat === '12') {
        return 'hh:mm A';
      } else {
        return 'HH:mm';
      }
    },
    placeholder() {
      let placeholder = '';
      this.data.inputs.forEach(function(time) {
        if (time.placeholder) {
          placeholder += time.placeholder + ' ';
        }
      });
      if (placeholder === '') {
        placeholder = '  ';
      }
      return placeholder;
    },
    customMessages() {
      return {
        required: 'Time is required',
        email: 'Please enter an valid emaill address',
        max: 'Reaching maximum input limit',
        min: 'Not reaching minimum input limit',
      };
    },
  },
  methods: {
    getDefaultValues() {
      let defaultValue = '';
      this.data.inputs.forEach(function(time) {
        if (time.defaultValue) {
          if (time.label === 'AM/PM') {
            defaultValue += ' ';
          } else {
            defaultValue += ':';
          }
          defaultValue += time.defaultValue;
        } else {
          defaultValue = '';
          return;
        }
      });
      this.input = defaultValue.substring(1, defaultValue.length);
    },
  },
};
