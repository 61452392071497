import { render, staticRenderFns } from "./UiConsent.vue?vue&type=template&id=aeab055a&scoped=true&"
import script from "./UiConsent.vue?vue&type=script&lang=js&"
export * from "./UiConsent.vue?vue&type=script&lang=js&"
import style0 from "./UiConsent.vue?vue&type=style&index=0&id=aeab055a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aeab055a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BdTooltip: require('/home/node/frontend/node_modules/@bowenstimber/designsystem/components/tooltips/BdTooltip.vue').default})
